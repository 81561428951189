import React, { useState, useContext, useEffect } from 'react'
import { graphql, Link } from 'gatsby'
import { Entity, fetchAuthenticatedContent, SubmitButtonComponent } from '@parallelpublicworks/entitysync'
import { makeStyles } from '@material-ui/core/styles'
import { Container } from '@material-ui/core'
import { ImpactContext } from '../../components/Layout'
import { Button, CardPerson, CircularProgress, Section, Spacer, Dialog, Image, Typography, Alert, Icon } from '../../components/library'
import { NotificationsField, InputField, InputDate } from '../../components/library/entitysync'
import { preparePuck } from '../../utils/symptoms-bluetooth'
import hexToRgbA from '../../utils/hexToRgbA'
import patchUser from '../../utils/patchUser'
import getPetTypes from '../../utils/getPetTypes'
import getPetLevels from '../../utils/getPetLevels'


const useStyles = makeStyles({
  buttons: {
    '& .MuiButton-root': {
      width: '100% !important',
      display: 'block !important',
      marginLeft: '0 !important'
    }
  }
})

const AccountPage = ({ data }) => {
  const classes = useStyles()
  const { userContext, user, profiles, wearableInitialAttrs, petLevel } = useContext(ImpactContext)
  const userState = userContext[0]
  const dispatch = userContext[1]
  const [loading, setLoading] = useState(true)
  const [notificationsInUser, setNotificationsInUser] = useState(false)
  const [notifications, setNotifications] = useState([])
  const [dwNotifications, setDWNotifications] = useState({ weekly: {}, daily: {} })
  const [phoneNumber, setPhoneNumber] = useState(null)

  const [failed, setFailed] = useState(null)
  const [preparingPuck, setPreparingPuck] = useState(false)
  const [puckWasPrepared, setPuckWasPrepared] = useState(false)
  const [attrs, setAttrs] = useState(wearableInitialAttrs)

  const dailyTitle = 'daily_notification'
  const weeklyTitle = 'weekly_notification'
  const medicinePattern = 'medicine_notification'

  const dailyMessage = "Hi from Project IMPACT! It's time for an asthma check-in. Please log into the app."
  const weeklyMessage = "Hi from Project IMPACT! It's time to complete your weekly to do list. Please log into the app."
  const medicineMessage = 'Reminder to take asthma medication'

  const petTypes = getPetTypes();
  const petLevels = getPetLevels();

  const SubmitButton = SubmitButtonComponent(({ onClick, variant, color, id, disabled, children, ...props }) => {
    return (
      <Button onClick={e => onClick(e)} variant={variant} color={color} id={id} disabled={disabled}>
        {children}
      </Button>
    )
  })

  const addMedicationNotification = (e, unsavedChanges, entityData, title) => {
    e.preventDefault()
    if (unsavedChanges?.attributes && !unsavedChanges?.attributes?.title) {
      unsavedChanges.attributes.title = title
    }
  }

  const weekdays = data.allTaxonomyTermWeekdays.nodes

  const hasStaticNotifications = data => {
    let userEntities = Object.entries(data)
    const containsDaily = userEntities.reduce((acc, e) => {
      return acc || (e[1].type == 'node--notification' && e[1]?.attributes?.title == dailyTitle && e[1].id !== `undefined`)
    }, false)
    const containsWeekly = userEntities.reduce((acc, e) => {
      return acc || (e[1].type == 'node--notification' && e[1]?.attributes?.title == weeklyTitle && e[1].id !== `undefined`)
    }, false)

    return containsDaily && containsWeekly
  }

  const getStaticNotifications = data => {
    let tmpDaily = {}
    let tmpWeekly = {}
    if (data && Object.entries(data)) {
      for (const [key, entity] of Object.entries(data)) {
        if (entity.type == 'node--notification') {
          if (entity?.attributes?.title == dailyTitle) {
            tmpDaily = entity
          } else if (entity?.attributes?.title == weeklyTitle) {
            tmpWeekly = entity
          }
        }
      }
    }

    return { daily: tmpDaily, weekly: tmpWeekly }
  }

  const getUserNotifications = data => {
    let userNotifications = []
    for (const [key, entity] of Object.entries(data)) {
      if (entity.type == 'node--notification') {
        userNotifications.push(entity)
      }
    }
    return userNotifications
  }

  const getPetLastLevel = (petType) => {
    let levels = petLevels.filter(level => level.pet_id == petType);
    return levels[levels.length - 1];
  }

  useEffect(() => {
    if (!phoneNumber && user?.attributes?.field_sms_number) {
      setPhoneNumber(user.attributes.field_sms_number)
    } else if (phoneNumber && phoneNumber.length >= 10) {
      let body = { data: { type: 'user--user', id: userState.currentUserId, attributes: { field_sms_number: phoneNumber } } }
      fetchAuthenticatedContent(userState.auth, dispatch, `user/user/${userState.currentUserId}`, 'PATCH', body).then(resp => {
        if (resp) {
          dispatch({
            type: 'CHANGE_ENTITY',
            data: resp.data,
            save: 'nocache'
          })
        }
      })
    }
  }, [phoneNumber])

  useEffect(() => {
    if (hasStaticNotifications(userState.userData)) {
      const retDWNotifications = getStaticNotifications(userState.userData)
      const retNotifications = getUserNotifications(userState.userData)
      setDWNotifications(retDWNotifications)
      setNotifications(retNotifications)
      setNotificationsInUser(true)
    }
  }, [userState.userData])

  useEffect(() => {
    if (typeof window !== `undefined` && !userState.loading) {
      const jsonapiEndpoint = 'node/notification?filter[uid.id]=' + userState.currentUserId
      const method = 'GET'
      fetchAuthenticatedContent(userState.auth, dispatch, jsonapiEndpoint, method).then(resp => {
        setNotifications(resp.data)
        // look for the weekly and daily notifications on user first
        let staticNotifications = {}
        if (hasStaticNotifications(userState.userData)) {
          staticNotifications = getStaticNotifications(userState.userData)
          // if the daily and weekly are not on the user, set them from the fetched content
        } else {
          staticNotifications = getStaticNotifications(resp.data)
        }
        setDWNotifications(staticNotifications)
        setLoading(false)
      })
    }
  }, [!userState.loading, notificationsInUser])

  useEffect(() => {
    if (failed === false) {
      patchUser(user?.id, userContext, {field_wearable_on: true})
      setPreparingPuck(false)
      setPuckWasPrepared(true)
    }
    if (failed === true) {
      setPuckWasPrepared(false)
      setAttrs({
        handleClose: e => {
          setPreparingPuck(false)
          setFailed(null)
        },
        title: 'Connection failed',
        description: 'Make sure the wearable is nearby and turned on.'
      })
    } else {
      setAttrs(wearableInitialAttrs)
    }
  }, [failed])
  
  if (loading || user === null) return <CircularProgress />
  return (
    <>
      <Container maxWidth="md">
        <Spacer />

        {profiles !== null && (
          <Section title="Profiles">
            <CardPerson profile={profiles.child} edit />
            <CardPerson profile={profiles.parent} edit />
            {
              petLevel <= 5 ? (() => {
                
                let lastLevel = getPetLastLevel(petTypes[0]);

                return (
                  <>
                    <CardPerson profile={profiles.pet} edit current />
                    <CardPerson profile={{ ...profiles.petType, avatar: `pup${lastLevel.level}`, petType: lastLevel.pet_id, name: 'Keep earning bones to unlock your pet' }} edit={false} silhouette />
                  </>
                )
              })() : (() => {
                  let currentLevel = petLevels.find(item => item.level == petLevel);
                  let { petType: profile } = profiles;

                  let name = '';
                  if(user?.attributes[`field_${currentLevel.pet_id}_name`]){
                    name = user.attributes[`field_${currentLevel.pet_id}_name`]
                  }
                  
                  let lastCurrentLevel = getPetLastLevel(currentLevel.pet_id);
                  let nextPetLevel = petLevels.find(item => item.level == lastCurrentLevel.level + 1);
                  let nextPetRangeLevels = petLevels.filter(item => item.pet_id == nextPetLevel.pet_id);
                  nextPetLevel = nextPetRangeLevels[nextPetRangeLevels.length - 1];
                  
                  return (
                  <>
                    <CardPerson profile={{ ...profile, avatar: `pup${petLevel}`, petType: currentLevel.pet_id, name }} edit current />
                    <CardPerson profile={{ ...profile, avatar: `pup${nextPetLevel.level}`, petType: nextPetLevel.pet_id, name: 'Keep earning bones to unlock your pet' }} edit={false} silhouette />
                    <CardPerson profile={profiles.pet} edit />
                    {
                      petTypes.map((petType, index) => {
                        
                        let lastLevel = getPetLastLevel(petType);

                        let name = '';
                        if(lastLevel && user?.attributes[`field_${lastLevel.pet_id}_name`]){
                          name = user.attributes[`field_${lastLevel.pet_id}_name`]
                        }
        
                        return lastLevel && lastLevel.level < petLevel ? <CardPerson key={index} profile={{ ...profile, avatar: `pup${lastLevel.level}`, petType: lastLevel.pet_id, name }} edit /> : null;
                      })
                    }
                  </>
                )})()
            }
          </Section>
        )}

        <Section title="Notifications">
          <Entity
            type="node--notification"
            source={dwNotifications.daily}
            autosave
            componentId={dwNotifications?.daily?.id ? dwNotifications.daily.id : 'daily-temp'}
          >
            <NotificationsField
              source={dwNotifications.daily}
              name={dailyTitle}
              label="Daily Check-in"
              message={dailyMessage}
              days={weekdays}
              switcher
              daily
            />
          </Entity>
          <Entity
            type="node--notification"
            source={dwNotifications.weekly}
            autosave
            componentId={dwNotifications?.weekly?.id ? dwNotifications.weekly.id : 'weekly-temp'}
          >
            <NotificationsField
              source={dwNotifications.weekly}
              name={weeklyTitle}
              label="Weekly To Do"
              message={weeklyMessage}
              days={weekdays}
              switcher
            />
          </Entity>
          {notifications
            ?.filter(notif => notif?.attributes?.title?.includes(medicinePattern) && notif?.id)
            ?.map((notification, index) => (
              <Entity type="node--notification" source={notification} autosave componentId={notification.id}>
                <NotificationsField
                  source={notification}
                  name={`${medicinePattern}_${index}`}
                  label="Medication"
                  message={medicineMessage}
                  days={weekdays}
                  switcher
                />
              </Entity>
            ))}
          <Entity type="node--notification" autosave componentId="add-notif-entity">
            <SubmitButton
              onSubmit={(e, unsavedChanges, entityData) => addMedicationNotification(e, unsavedChanges, entityData, medicinePattern)}
              color="tertiary"
            >
              + Add Medication Reminder
            </SubmitButton>
          </Entity>
        </Section>
        <Entity type="user--user" source={user} componentId="user-entity">
          <Section title="Initialize Symptom Watch">
            {puckWasPrepared ? (
              <Alert
                alignMiddle
                color="green"
                title="Well done"
                icon={<Icon name="ok" fg="#ffffff" bg={hexToRgbA('#ffffff', 0.15)} size="md" />}
              />
            ) : (
              <Typography>Initialize your watch if you recently added new batteries.</Typography>
            )}

            <Button
              color="tertiary"
              variant="contained"
              onClick={e => {
                setAttrs(wearableInitialAttrs)
                setPreparingPuck(true)
                preparePuck((d) => {
                  if(user && user?.attributes?.display_name && user.attributes.display_name.includes('nopucktest')){
                    setFailed(false)
                  }else{
                    setFailed(!d)
                  }
                });
              }}
            >
              Initialize
            </Button>
          </Section>

          <Section title="Account Info" help="Username and email cannot be changed">
            <InputField field="name" label="Username" disabled />
            <InputField field="mail" label="Email" disabled />
            <InputField field="field_sms_number" value={phoneNumber} onChange={e => setPhoneNumber(e.target.value)} label="Phone" />
            <InputDate field="field_start_date_iso" label="Start date of participation" disablePast />
            <div className={classes.buttons}>
              <Button variant="contained" color="tertiary" component={Link} to="/logout">
                Logout
              </Button>
            </div>
          </Section>
        </Entity>
      </Container>
      <Dialog id="puck-dialog" open={preparingPuck} {...attrs}>
        <Image src={'/watch.png'} width="40%" aspectRatio={0.5} />
      </Dialog>
    </>
  )
}

export default AccountPage

export const query = graphql`
  query {
    allTaxonomyTermWeekdays {
      nodes {
        drupal_id
        name
      }
    }
  }
`
